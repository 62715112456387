.sec-learn {

    position: relative;
    padding: 50px 0 95px;
    z-index: 1;

    article {
        max-width: $md;
        margin: 0 auto;
        a {
            color: $green2;
            text-decoration: underline;
            transition: text-decoration 0.2s ease;
    
            &:hover {
                text-decoration: none;
            }
        }
        img {
            max-width: 100%;
            height: auto;
            border: 15px solid #1E2121;
            border-radius: 10px;
            position: relative;
            z-index: 100;
        }
    }

    h1 {
        font-size: 50px;
        margin: 0.5em 0 0.2em;
    }

    h2 {
        margin: 1.5em 0 0.5em;
    }

    .date {
        margin-bottom: 2.5em;
        font-size: 0.83em;
    }

    .bg-right {
        top: 0;
        transform: translate(10%, -35%);
    }

    .btn {
        margin-top: 0;
    }

    .back-link {
        font-weight: 300;
        font-size: 16px;
        height: 36px;
        padding: 8px 16px;
        img {
            margin-right: 10px;
        }
    }

    p {
        line-height: 1.88em;
        margin-bottom: 1.75em;
    }

    .align-center {
        display: block;
        margin: 0 auto;
    }

    figure {
        text-align: center;
        margin: 1em 0 2em;
        img {
            border-radius: 20px;
            overflow: hidden;
            max-width: 100%;
            height: auto;
        }
    }

    picture {
        display: block;
        margin: 1em 0 2em;
        img {
            border-radius: 20px;
            overflow: hidden;
        }
    }

    .summary {        
        margin-top: 4em;        
        h5 {
            margin: 0.5em 0 1em;
            font-size: 24px;
        }
    }

    .sec-learn__item {
        margin-bottom: 120px;
        border-radius: 10px;
        background-color: $gray;
        display: flex;
        flex-direction: row;
        align-items: center;

        .title {            
            padding: 10px;
            .date {
                margin: 20px 0 0;
            }
        }

        .image {
            margin: 25px;
            img {                
                max-height: 200px;
                height: auto;
                border: none;
                border-radius: 0;                
            }
        }

        a {
            text-decoration: none;
            font-size: 1.33em;
        }

        &:hover {
            border-color: $green2;
            box-shadow: 0px 0px 30px rgba(102, 253, 208, 0.4);
        }
    }

    .col-card-promo {
        .sec-learn__item {
            min-height: 490px;
            flex-direction: column;
            .title {
                max-width: 350px;
                text-align: center;
                padding: 15px 0 0;
            }
            .image {
                width: 100%;
                max-width: 390px;
                height: auto;
                min-height: 349px;
                margin: 25px 0 0;
                text-align: center;
                img {
                    max-width: 100% !important;                    
                    max-height: unset;
                }
            }    
        }
    }

    @media(min-width: $lg) {
        padding-bottom: 60px;
        .sec-bg {
            opacity: 1;
            top: -100px;
            position: absolute;
            border: none;
        }
    }

    @media(max-width: $sm) {
        .sec-learn__item {
            margin-bottom: 30px;
            flex-direction: column;
            min-height: unset;
            .image {
                min-height: unset;
                img {
                    width: 90%;
                }
            }
            .title {
                text-align: center;
                padding: 25px !important;
            }
        }
    }

    @media(min-width: $xl) {
        .summary {
            padding: 2em;
            h5 {
                text-align: center;
            }
        }
    }

}

#article-menu {

    padding-bottom: 30px;

    ul {

        margin-bottom: 0;

        &::before {
            content: "Table of contents:";
            font-weight: 700;
            font-size: 28px;
            margin-bottom: 10px;
            display: block;
        }
    }

    li {
        margin-left: 15px;
        padding-bottom: 3px;

        a {
            color: #51eeda;
        }
    }

    li.item-h3 {
        margin-left: 40px;
    }

    li.item-h4 {
        margin-left: 65px;
    }

    li.item-h5 {
        margin-left: 90px;
    }

    li.item-h6 {
        margin-left: 115px;
    }

}