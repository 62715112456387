.s-header {
    height: 60px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 20;

    .container {
        height: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    @media(min-width: $lg) {
        height: 80px;
    }
}

.s-header__logo {
    img {
        display: block;
        width: 110px;
        height: auto;
    }

    @media(min-width: $lg) {
        margin-right: 10px;
        position: relative;
        top: -2px;
    }

    @media(min-width: $xl) {
        margin-right: 40px;

        img {
            width: 156px;
        }
    }
}

.s-header__nav {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    a {
        text-decoration: none;
        display: block;
        padding: 4px 0;
        margin-left: 24px;
        font-weight: bold;

        &:hover {
            color: $green1;
        }
    }

    @media(min-width: $sm) {
        a {
            padding: 4px 8px;
            margin: 0 8px;
            font-size: 16px;
        }
    }
}

.s-header__socails {
    display: none;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;

    .socials {
        justify-content: center;

        img {
            width: 30px;
            height: 30px;
        }
    }

    @media(min-width: $lg) {
        display: flex;
        position: static;

        .socials {
            img {
                width: 24px;
                height: 24px;
            }
        }

        .btn {
            margin-right: 30px;
        }
    }

    @media(min-width: $xl) {
        .socials {
            img {
                width: 30px;
                height: 30px;
            }
        }
    }
}

.external-link {
    text-decoration: none;
    color: #fff;

    svg {
        display: inline-block;
        width: 14px;
        height: 14px;
        vertical-align: middle;
        margin-top: -2px;
        fill: #fff;
        opacity: 0.4;
    }

    &:hover {
        svg {
            fill: $green1;
            opacity: 1;
        }
    }
}

.sticky {

    .s-header {
        position: fixed;
        z-index: 9;
        background-color: rgba(30, 33, 33, .75);
        backdrop-filter: blur(8px);
    }

    .s-main {
        margin-top: 80px;
    }

}

.scrolltop {
    position: fixed;
    right: 1em;
    bottom: 0.8em;
    font-size: 5em;
    display: block;
    z-index: 7;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAzNyAyMyIgaGVpZ2h0PSIyMyIgd2lkdGg9IjM3Ij48ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHRyYW5zZm9ybT0ibWF0cml4KC0xIDAgMCAtMSAzNyAyMykiPjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNNS4wMjk4OSAwLjkzNjUyM0wwLjQ2NDg0NCA1LjM4NzIxTDE4LjQ2NDggMjIuOTM2M0wzNi40NjQ4IDUuMzg3MjFMMzEuODk5OCAwLjkzNjUyM0wxOC40NjQ4IDE0LjAzNDlMNS4wMjk4OSAwLjkzNjUyM1oiIGZpbGw9InVybCgjcGFpbnQwX2xpbmVhcikiIC8+PGRlZnM+PGxpbmVhckdyYWRpZW50IGlkPSJwYWludDBfbGluZWFyIiB4MT0iMTguNDY0OCIgeTE9IjAuOTM2NTIzIiB4Mj0iMTguNDY0OCIgeTI9IjIyLjkzNjMiIGdyYWRpZW50VW5pdHM9InVzZXJTcGFjZU9uVXNlIj48c3RvcCBzdG9wLWNvbG9yPSIjNEFGRUJGIiAvPjxzdG9wIG9mZnNldD0iMSIgc3RvcC1jb2xvcj0iIzUxRUVEQSIgLz48L2xpbmVhckdyYWRpZW50PjwvZGVmcz48L2c+PC9zdmc+);
    width: 37px;
    height: 23px;
    text-indent: -37px;
    overflow: hidden;
    cursor: pointer;
    transition: opacity 1s ease-out;
    opacity: 0;

    &.visible {
        opacity: 1;
    }

}
