/* Modal 
--------------------------------*/

.modal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow-y: auto;
    z-index: 100;
    background: rgba(#fff, 0.2);
    backdrop-filter: blur(5px);
}

.modal__inner {
    position: relative;
    z-index: 1;
    margin: 0;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;
    min-height: 100%;
    overflow: auto;

    .container {
        padding-top: 40px;
        padding-bottom: 40px;
    }

}

.modal__close {
    padding: 15px;
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;

    svg {
        display: block;
        width: 30px;
        height: 30px;
    }

    &:hover {
        opacity: 0.7;
    }

    // @media(min-width: $lg) {
    //     top: 38px; 
    //     right: 35px;
    //     svg {
    //         display: block;
    //         width: auto;
    //         height: auto;
    //     }
    // }
}

.modal__header {
    .h2 {
        font-size: 24px;
        line-height: 50px;
    }

    @media(min-width: $md) {
        .h2 {
            font-size: 36px;
            line-height: 50px;
        }
    }
}

.modal__content {
    padding: 20px 20px 30px;
    position: relative;
    margin: 0 auto;

    width: 100%;
    max-width: 816px;

    display: flex;
    flex-direction: column;
    border-radius: 20px;
    background: $black;

    p {
        word-break: break-word;
    }

    @media(min-width: $sm) {
        padding: 30px 30px 40px;

    }
}



// 

.m-item__header {
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;

    .img {
        width: 64px;
        height: 64px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 20px;
        overflow: hidden;
        border-radius: 50%;
        // border: 10px solid #000;
        flex-shrink: 0;

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }

        &.white {
            background-color: #fff;
        }

        &.black {
            background-color: #000;

        }
    }

    h3 {
        margin-bottom: 8px;

        span {
            vertical-align: middle;
        }
    }

    @media(min-width: $sm) {
        flex-direction: row;
        align-items: center;
        padding-right: 40px;

        .img {
            width: 98px;
            height: 98px;
            margin-bottom: 0;
            margin-right: 24px;
        }
    }

    @media(min-width: $lg) {
        .item-docs {
            font-size: 16px;
        }
    }

}

.m-item__info {
    background-color: #000;
    border-radius: 10px;
    padding: 1em 1em 1.5em;
    margin-bottom: 24px;

    .heading {
        color: #ACBEC0;
        margin-bottom: 10px;
    }

    .token-name {
        font-weight: bold;
    }

    .tags {
        margin-top: 24px;

        .tag {
            margin-right: 8px;
            margin-bottom: 8px;
        }
    }

    @media(min-width: $lg) {
        font-size: 16px;
        line-height: 150%;
        padding: 18px 24px 12px;

        .info-right {
            text-align: right;
        }

        .tags {
            margin-top: 24px;

            .tag {
                margin-right: 0;
                margin-left: 8px;
            }
        }
    }
}

.m-item__nets {
    margin-bottom: 14px;
    font-size: 0;

    .item__net {
        display: inline-flex;
        align-items: center;
        font-size: 14px;
        font-weight: bold;
        height: 32px;
        padding: 0 14px;
        background-color: rgba(#fff, 0.1);
        border-radius: 30px;
        margin-right: 10px;
        margin-bottom: 10px;

        img {
            display: block;
            height: 18px;
            width: 18px;
            margin-right: 8px;
        }

        span, a {
            display: flex;
            align-items: center;
            text-decoration: none;
        }

        i {
            display: block;
            margin-right: 8px;
            text-indent: 200%;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            background-size: $sprite1 $sprite2 !important;
            max-width: 24px;
            background: url($icons) 0 9.266409266409266% no-repeat;
        }

    }
}

.m-item__tags {
    margin-bottom: 32px;
    color: $green2;

    a {
        text-decoration: none;
    }

    .item__tag {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

.to-project-page {
    opacity: 1;

    &:hover {
        opacity: 1;
    }
}