
// Chatbot --------------------

.rcb-chat-bot, .rcb-chat-tooltip {
    z-index: 100 !important;
}

.rcb-window-embedded {
    .rcb-chat-window {
        width: 100% !important;
    }
}

.rcb-window-full {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 4%;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(14,14,16,0.9);
    }

    .rcb-chat-window {
        width: 100% !important;
        max-width: $lg !important;
        margin: 0 auto !important;
        height: 100% !important;
        position: static !important;
        right: unset !important;
        bottom: unset !important;

        @media(max-width: $sm) {
            width: 91% !important;
            height: 95% !important;
        }
    
    }

    .rcb-options-container, .rcb-bot-message {
        margin-left: 30px;
    }

    .rcb-user-message {
        margin-right: 30px;
    }

    .rcb-bot-message, .rcb-user-message {
        line-height: 1.6em;
        padding: 25px 25px 20px !important;

        h1, h2, h3, h4, h5 {
            font-size: 1em;
            line-height: 1em;
            margin-top: 25px;
        }

        ol, ul {
            padding-left: 30px;
        }
    }

    .rcb-chat-input {
        padding: 16px 30px 30px !important;
    }

    .rcb-chat-input-textarea {
        font-size: 1.1em !important;
    }

}

.rcb-chat-header-container {
    padding: 12px 20px !important;
}

.rcb-chat-body-container {
    background-image: url('../pics/mascot/idea.png');
    background-repeat: no-repeat;
    background-position: right 15px bottom 10px;

    p {
        a {
            color: #2b675f;
        }
    }

    &::-webkit-scrollbar {
        width: 15px !important;
    }
    
    &::-webkit-scrollbar-track {
        background: #22201B !important;
        -webkit-box-shadow: inset 0 0 3px rgba(14,16,16,0.3) !important;
    }
    
    &::-webkit-scrollbar-thumb {
        background-color: $green1 !important;
        border-radius: 20px !important;
        border: 3px solid #22201B !important;
        -webkit-box-shadow: inset 0 0 3px rgba(14,16,16,0.5) !important;
    }
    
}

.rcb-bot-message, .rcb-user-message {
    font-size: unset !important;
    font-family: 'Acherus Feral', sans-serif !important;
    white-space: normal !important;
    ol, ul {
        padding-left: 18px;
    }
}

.rcb-chat-input {
    border-top: 0 !important;
    padding-bottom: 15px !important;
}

.rcb-chat-input-textarea {
    min-height: unset !important;
    padding: 10px !important;
}

.rcb-send-button {
    height: 38px !important;
    width: 50px !important;
}

.rcb-options, .rcb-view-history-button, .rcb-line-break-text {
    background: none !important;
    border: none !important;
    padding: 0 5px !important;
    filter: none !important;
    color: #ffffff !important;
}
