.promo-book {
    text-align: center;
}

.promo-book__link {
    display: inline-block;
    text-align: center;
    font-weight: bold;
    text-decoration: none;

    .book {
        display: inline-block;
        padding: 15px;
        border-radius: 10px;
        border: 1px solid $green1;
        margin: 0 auto;
        position: relative;

        background: rgb(235, 52, 176);
        background: linear-gradient(0deg, rgba(235, 52, 176, 1) 0%, rgba(139, 227, 255, 1) 68%, rgba(74, 254, 191, 1) 100%);


        .page,
        .cover {
            display: block;
            height: auto;
            width: 200px;
        }

        .download {
            position: absolute;
            bottom: -8px;
            right: -8px;
            z-index: 4;
            transition: opacity 0.2s ease;
        }

        .cover {
            border: 1px solid $green1;
            position: relative;
            z-index: 3;
            transition: transform 0.3s ease;

        }

        .page {
            position: absolute;
            z-index: 1;
            border: 1px solid $green1;
            transition: transform 0.3s ease;

        }

        .page-left {
            transform-origin: bottom left;
        }

        .page-right {
            transform-origin: bottom right;
        }
    }

    .caption {
        margin-top: 8px;
        transition: opacity 0.2s ease;
    }


    &:hover {

        .cover {
            transform: scale(1.2);
        }

        .caption,
        .download {
            opacity: 0;
        }

        .page-left {
            transform: scale(1.1) rotate(8deg);
        }

        .page-right {
            transform: scale(1.15) rotate(-8deg);
        }

    }
}