.btn {
    display: inline-flex;
    height: 54px;
    color: $black;
    border-radius: 10px;
    background: $grad;
    border: 0;
    font-size: 16px;
    line-height: 21px;
    font-weight: bold;
    text-decoration: none;
    padding-right: 1em;
    padding-left: 1em;
    cursor: pointer;
    justify-content: center;
    align-items: center;

    &:hover {
        background: #21CE92;
        color: #fff;
    }

    &.btn-sm {
        height: 32px;
        font-size: 14px;
        padding: 8px 14px 6px;
        border-radius: 8px;

        .arrow {
            width: 16px;
            height: 16px;
            opacity: 0.6;
            margin-right: -4px;
            margin-left: 4px;
        }
    }

    &.btn-gray {
        background: #2B2D2D;
        color: #fff;
        border: 1px solid transparent;

        &:hover {
            background: transparent;
            border-color: #fff;
        }

        &.active {
            border-color: $green1;
            color: $green1;
            background: transparent;
        }
    }

    @media(min-width: $xl) {
        font-size: 18px;
    }
}

.btn-outline {
    background: transparent;
    border: 1px solid $green1;
    color: $green1;

    svg {
        margin-right: 12px;
    }

    .white {
        fill: #fff;
    }

    .green {
        fill: $green1;
    }

    &:hover {
        background: #21CE92;
        color: #fff;

        .green {
            fill: #fff;
        }
    }

    &.disabled,
    &:disabled {
        opacity: 0.4;
        cursor: default;
        background: #fff;

        &:hover {
            color: $black;
        }
    }
}

.btn-link {
    background: transparent;
    border-color: transparent;
    padding-left: 0;
    padding-right: 0;
    color: $green1;

    svg {
        fill: $green1;
    }

    &:hover {
        background: transparent;
        border-color: transparent;
        color: $green2;

        svg {
            fill: $green2;
        }
    }
}

input:checked+.btn-gray,
input:checked+.btn-gray:focus,
input:checked+.btn-gray:hover {
    border-color: #fff;
    //color: $green1;
    background: transparent;
}

.checkbox {
    display: inline-block;
    position: relative;
    font-size: 14px;
    line-height: 20px;
    cursor: pointer;
    padding: 1px 0;
    padding-left: 32px;

    input {
        position: absolute;
        left: -99999px;

        &:checked+.check::before {
            background-color: $black;
        }

        &:disabled+.check {
            background-color: rgba(#fff, 0.4);
        }
    }

    .check {
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin-right: 6px;
        margin-top: -2px;
        font-size: 0;
        line-height: 0;
        width: 20px;
        height: 20px;
        border-radius: 5px;
        background-color: #fff;
        position: absolute;
        left: 0;

        &::before {
            content: '';
            display: block;
            box-sizing: border-box;
            width: 12px;
            height: 12px;
            border-radius: 8px;
            background-color: transparent;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

        }
    }

    .check-text {
        display: inline-block;
    }

    @media(min-width: $md) {
        &:not(.sm) {
            font-size: 18px;
            line-height: 20px;
            // .check {
            //     margin-top: -8px; 
            // }
        }
    }
}

// simple input 

.input-control {
    width: 100%;
    background: #fff;
    border: 0;
    box-sizing: border-box;
    border-radius: 10px;
    height: 55px;
    font-size: 18px;
    line-height: 21px;
    padding: 18px 16px 15px;
    font-weight: 300;

    &::-webkit-input-placeholder {
        color: #ACBEC0;
        font-weight: 300;
    }

    &::-moz-placeholder {
        color: #ACBEC0;
        font-weight: 300;
    }

    &:-ms-input-placeholder {
        color: #ACBEC0;
        font-weight: 300;
    }

    &:-moz-placeholder {
        color: #ACBEC0;
        font-weight: 300;
    }

    &:focus {
        box-shadow: 0px 4px 50px rgba(102, 253, 208, 0.4);
    }

    &.disabled,
    &:disabled {
        opacity: .4;
        color: #ACBEC0;
    }

    &[readonly]:focus {
        box-shadow: none;
    }

    &.has-error {
        box-shadow: 0px 4px 50px rgba($red, 0.8);
    }


    &.input-sm {
        height: 32px;
        font-size: 16px;
        line-height: 16px;
        padding: 6px 12px 2px;
        border-radius: 8px;

        &:focus {
            box-shadow: 0px 0px 25px rgba(102, 253, 208, 0.5);
        }

        &.has-error {
            box-shadow: 0px 0px 25px rgba($red, 1);
        }
    }
}

.control-gray {
    background: rgba(#fff, 0.1);
    border: 1px solid #696969;

    color: rgba(#fff, 0.8);

    &:focus {
        box-shadow: none;
        border-color: #fff;
    }
}

.control-search {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    padding: 28px 30px 2px 28px;
    background-image: url($img-search);
    background-repeat: no-repeat;
    background-position: top 55% right 16px;
    text-indent: -90px;
    overflow: hidden;
    cursor: pointer;
}

.search-input {
    position: relative;
}