
// Back -------------------- 

.sec-back {
    padding: 40px 0 0;
    margin-bottom: -20px;
    position: relative;
    z-index: 8;
}


// Intro -------------------- 

.sec-intro {
    position: relative;
    padding-top: 40px;
    padding-bottom: 60px;
    z-index: 10;

    .btn {
        margin-top: 36px;
    }

    h1 {
        margin-bottom: 20px;
    }

    @media(min-width: $lg) {
        padding: 70px 0 70px;

        h1 {
            margin-bottom: 32px;
        }
    }

    @media(min-width: $xl) {
        padding: 80px 0 95px;
    }

}


// News ---------------------

.swiper-news-container {
    position: relative;
    margin: 16px 0 8px;

    &:hover {

        .swiper-news-button-next,
        .swiper-news-button-prev {
            opacity: 1;
        }
    }

    @media(min-width: $lg) {
        margin: 0;
    }
}

.swiper-pagination {
    width: 100% !important;
    text-align: center !important;
    bottom: -30px !important;
}

.swiper-pagination-bullet {
    background: $gray2 !important;
    margin: 0 4px !important;
    opacity: 1 !important;


    &.swiper-pagination-bullet-active {
        background: #fff !important;
    }
}

.news-card {
    border-radius: 16px;
    border: 1px solid $gray2;
    overflow: hidden;
    // padding: 16px;
    transition: border-color 0.2s ease;

    &:hover {
        border-color: $green1;
    }

    img {
        display: block;
        width: 100%;
    }

    a {
        @media(max-width: $sm) {
            min-height: unset !important;
        }
    }
}

.swiper-news-button-next,
.swiper-news-button-prev {
    position: absolute;
    top: 50%;
    right: 0;
    z-index: 2;
    width: 54px;
    height: 54px;
    transform: translate(50%, -50%);
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s ease;

    svg {
        display: block;
    }
}

.swiper-news-button-prev {
    left: 0;
    transform: translate(-50%, -50%);
}

// Cards -------------------- 

.sec-cards {
    position: relative;
    padding-bottom: 60px;
    z-index: 11;

    @media(min-width: $lg) {
        padding-bottom: 100px;
    }
}

.sec-cards__filter {
    margin-bottom: 24px;
    border-bottom: 1px solid rgba(#fff, 0.1);

    .filter-nets {
        margin-bottom: 24px;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        justify-content: flex-start;
    }

    @media(min-width: $lg) {
        margin-bottom: 40px;
    }
}

.filter-tag {
    &.first .btn {
        min-width: 100px;
    }

    &.last .btn {
        color: #4afebf;
        font-size: 1.33em;
        margin-right: 0;
        padding: .1em .8em 0;
        min-width: 64px;
    }

    input {
        display: none;
    }

    i {
        text-indent: 200%;
        white-space: nowrap;
        overflow: hidden;
        display: inline-block;
        background-size: $sprite1 $sprite2 !important;
    }

    .btn-gray {
        margin: 0 12px 12px 0;
        border-radius: 30px;
        height: 34px;
        font-size: 14px;
        line-height: 1em;

        img, i {
            display: block;
            margin-right: 8px;
        }
    }

    @media(min-width: $lg) {
        .btn-gray {
            height: 54px;
            font-size: 18px;
        }
    }

}

.sec-cards__item {
    margin-bottom: 30px;
    position: relative;
    background-color: $gray;
    height: 382px;
    border-radius: 20px;
    border: 1px solid transparent;
    transition: border-color 0.3s ease, box-shadow 0.3 ease;
    padding-top: 24px;
    text-align: center;
    cursor: pointer;

    &:hover {
        border-color: $green2;
        box-shadow: 0px 0px 30px rgba(102, 253, 208, 0.4);

        .tag-status {
            opacity: 1;
        }
    }

    .tag-status {
        position: absolute;
        top: 12px;
        right: 12px;
        opacity: 0.8;
        transition: opacity 0.3s ease;
    }

    .img {
        width: 162px;
        height: 162px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto 24px;
        overflow: hidden;
        border-radius: 50%;
        padding: 0;
        border: 10px solid $black;

        img {
            display: block;
            max-width: 100%;
            max-height: 100%;
        }

        &.white {
            background-color: #fff;
        }

        &.black {
            background-color: $black;

        }
    }

    .tag {
        margin: 0 4px;
    }

    .info {
        padding: 0 24px;
    }

    .name {
        font-weight: 700;
        font-size: 24px;
        margin-bottom: 24px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .nets {
        margin-bottom: 20px;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            display: block;
        }

        span {
            opacity: 0.6;
            margin: 0 4px;
        }

        i {
            text-indent: 200%;
            white-space: nowrap;
            overflow: hidden;
            display: inline-block;
            background-size: $sprite1 $sprite2 !important;
        }

    }
}

//- Video --------------
.sec-video {
    padding: 60px 0;

    @media(min-width: $lg) {
        padding: 100px 0;
    }
}

//- Backgounds 


.sec-bg {
    display: block;
    position: absolute;
    z-index: -1;
    opacity: 0.6;

    &.bg-left {
        left: 0;
        z-index: -1;
    }

    &.bg-right {
        right: 0;
        z-index: -1;
    }

    img {
        display: block;
    }
}

.sec-intro {
    .bg-left {
        top: 40px;
    }

    .bg-right {
        top: 0;
        transform: translate(10%, -35%);
    }
}


.sec-form {
    .sec-bg {
        bottom: -50px;
        opacity: 0.4;
    }
}


// Form --------------------

.sec-form {
    position: relative;
    padding: 50px 0;
    z-index: 1;

    h2 {
        margin-bottom: 20px;
    }

    @media(min-width: $lg) {
        padding: 70px 0;

        h2 {
            margin-bottom: 30px;
            font-size: 48px;
        }
    }

    @media(min-width: $xl) {
        padding: 100px 0 100px;
    }
}

.form__socials {
    margin-top: 60px;

    .socials {
        flex-wrap: wrap;
        margin-left: -6px;

        li {
            padding: 0 6px 12px;

            img {
                width: 35px;
                height: 35px;
            }
        }
    }

    @media(min-width: $xl) {
        margin-top: 100px;

        .socials li img {
            width: 40px;
            height: 40px;
        }
    }
}
