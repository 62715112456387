// digest --------------------
.sec-digest {
    position: relative;
    z-index: 1;
    padding: 40px 0 60px;

    @media(min-width: $lg) {
        .sec-bg {
            top: -200px;
        }
    }
}

.digest-list {
    margin-top: 40px;

    @media(min-width: $lg) {
        margin-bottom: 60px;
    }
}

// digest accordion --------------------
.accordion-container {
    .panel {
        transition: 0.4s;
        border-bottom: 1px solid rgba(#fff, 0.1);

        &.active {
            .heading {
                color: $green2;

                .arrow {
                    transform: rotate(-180deg);
                    fill: $green2;
                }
            }

            .content {
                display: block;
                max-height: none !important;
            }
        }
    }

    .content {
        display: none;
        //max-height: 0;
        overflow: hidden;
        transition: padding-top 0.3s ease, max-height 0.2s ease;

        p {
            margin: 0 0 1em;

            &:first-child {
                margin-top: 1.2em;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }

    }

    .heading {
        position: relative;
        @include text-18-24();
        font-weight: bold;
        padding-right: 50px;
        cursor: pointer;
        margin: 0;
        min-height: 60px;
        display: flex;
        align-items: center;

        .arrow {
            transition: transform 0.25s ease;
            fill: #fff;
            margin-left: 8px;
        }

        &:hover {
            color: $green1;

            .arrow {
                fill: $green1;
            }
        }

    }

    @media(min-width: $xl) {
        .content {
            padding-right: 50px;
        }

        .heading {
            min-height: 80px;
        }
    }
}

.digest-item {
    display: flex;
    text-decoration: none;
    font-size: 14px;
    line-height: 1.5em;
    padding: 12px 0;
    margin-bottom: 30px;

    &:hover {
        //color: rgba(#fff, 0.6);

        .digest-title {
            color: $green1;
        }

        .digest-item__cover {
            .download {
                opacity: 1;
            }

            .cover {
                opacity: 0.6;
            }
        }

    }
}



.digest-item__cover {
    display: block;
    position: relative;

    .cover {
        display: block;
        width: 100%;
        transition: opacity 0.25s ease;
    }

    .pdf {
        position: absolute;
        top: 0;
        right: 14px;
        margin-top: -12px;
    }

    .download {
        opacity: 0;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 30%);
        transition: opacity 0.25s ease;
    }
}

.digest-item__desc {}

.digest-title {
    font-size: 18px;
    line-height: 1.2em;
    margin-bottom: 16px;
    transition: color 0.25s ease;
}

.digest-new {
    display: inline-flex;
    align-items: center;
    vertical-align: middle;
    height: 24px;
    font-size: 12px;
    line-height: 1em;
    font-weight: bold;
    text-transform: uppercase;
    padding: 2px 8px 0;
    border-radius: 30px;
    background: $grad;
    color: $black;
}