// infographics --------------------
.sec-info {
    position: relative;
    z-index: 1;
    padding: 40px 0 60px;

    figure {
        margin: 2em;
        max-width: fit-content;

        &:hover {
            border-color: $green2;
            box-shadow: 0px 0px 30px rgba(102, 253, 208, 0.4);
        }

        img {
            max-width: 100%;
        }
    }

    figcaption {
        font-size: 1em;
        font-style: italic;
        text-align: center;
        padding-bottom: 1em;
    }

    a {
        text-decoration: none;
    }

    @media(min-width: $lg) {
        .sec-bg {
            top: -200px;
        }
    }
}

.info-list {
    margin-top: 40px;

    a {
        text-decoration: underline;
    }

    @media(min-width: $lg) {
        margin-bottom: 60px;
    }
}
