.s-footer {
    position: relative;
    background-color: $gray;
    padding: 22px 0;
    z-index: 1;

    @include text-12-14();
    font-weight: bold;

    a {
        text-decoration: none;
        color: $green1;
    }

    .s-logo {
        display: block;
        img {
            display: block;
            width: 110px;
            height: auto;
        }
    }

    .links {
        margin-top: 16px;
    }

    .socials {
        justify-content: flex-end;
    } 

    @media(min-width: $lg) {
        padding: 18px 0 16px;
        .links {
            margin-top: 0;
            text-align: center;
        }
  
    }

    @media(min-width: $xl) {
        .s-logo {
            img {
                width: 156px;
            }
        }
    }
}