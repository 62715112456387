.sec-error {
    position: relative;
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    color: #fff;
    justify-content: center;
    align-items: center;
    z-index: 1;

    .socials {
        display: flex;
        margin: 0;
        left: 0;
        width: 100%;
        justify-content: center;
        flex-wrap: wrap;

        li {
            padding: 0 4px 8px;
        }
    }

    .sec-bg {
        display: block;
        opacity: 0.4;
        position: absolute;

        &.bg-right {
            top: 10%;
            right: 0;

        }

        &.bg-left {
            top: 35%;
            left: 0;
        }

        @media(min-width: $xl) {
            opacity: 1;
        }
    }

    .btn {
        display: inline-flex;
        padding: 0 20px;
        width: auto;
    }

    @media(min-width: $xl) {
        padding: 60px 0;
    }
}


.sec-error__text {
    text-align: center;
    padding: 0 20px;

    h1 {
        font-size: 36px;
        line-height: 50px;
        margin: 0 0 0.1em;

        @media(min-width: $lg) {
            font-size: 82px;
            line-height: 90px;
        }
    }

    .h1_sub {
        font-size: 24px;
        line-height: 1em;
        margin: 0 0 50px;
        font-weight: bold;

        @media(min-width: $lg) {
            font-size: 48px;
        }
    }

    .actions {
        margin-bottom: 60px;

        span {
            display: block;
            font-size: 8px;
            opacity: 0;
            margin: 0 14px;
        }

        @media(min-width: $sm) {
            span {
                display: inline-block;
                vertical-align: middle;
                opacity: 0.6;
                font-size: 18px;
            }
        }
    }
}