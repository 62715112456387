* {
    box-sizing: border-box;
    outline: 0;
}

html,
body {
    min-width: 375px;
    background-color: $black;
    color: #fff;
    font-family: 'Acherus Feral', sans-serif;
    font-weight: 300;
    scroll-behavior: smooth;
}

body {
    @include text-main();
}

body::-webkit-scrollbar {
    width: 15px;
}

body::-webkit-scrollbar-track {
    background: $black;
    -webkit-box-shadow: inset 0 0 3px rgba(14,16,16,0.3);
}

body::-webkit-scrollbar-thumb {
    background-color: $green1;
    border-radius: 20px;
    border: 3px solid $black;
    -webkit-box-shadow: inset 0 0 3px rgba(14,16,16,0.5);
}

.container {
    position: relative;
    z-index: 2;
}


.wrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.s-main {
    flex-grow: 1
}


h1,
.h1 {
    @include h1();
}

h2,
.h2 {
    @include h2();
}

h3,
.h3 {
    @include h3();
}

p {
    margin: 0 0 1em;

    a {
        color: $green2;
        text-decoration: underline;
        transition: text-decoration 0.2s ease;

        &:hover {
            text-decoration: none;
        }
    }
}

a {
    color: inherit;

    &:hover {
        text-decoration: none;
    }
}

.divider {
    width: 50%;
    height: 1px;
    background-color: $green2;
    position: relative;

    &::before {
        content: '';
        height: 2px;
        width: 56px;
        background-image: url($dashed);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    &.left {
        &::before {
            right: -66px;
        }
    }

    &.right {
        margin-left: 50%;

        &::before {
            left: -66px;
        }
    }
}

.socials {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;

    li {
        padding: 0 3px;
    }

    img {
        display: block;
        border-radius: 50%;
        width: 25px;
        height: 25px;
    }

    a {
        display: block;
        border-radius: 50%;

        &:hover {
            box-shadow: 0px 4px 40px #66FDD0;
        }
    }

    @media(min-width: $xl) {
        img {
            width: 30px;
            height: 30px;
        }
    }
}

.text-grad {
    color: $green1;

    @supports (-webkit-background-clip: text) and (-webkit-text-fill-color: transparent) {
        background-image: $grad;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

}

.tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 2px 12px 0;
    background-color: $gray2;
    color: $gray3;
    font-size: 14px;
    line-height: 1em;
    border-radius: 4px;
}

.img-fluid {
    display: block;
    max-width: 100%;
    height: auto;
}

// cookies block

.sec-cookie {
    position: fixed;
    bottom: 0;
    left: 0;
    height: auto;
    max-height: 100vh;
    width: 100%;
    background-color: $gray;
    padding: 30px 0 20px;
    z-index: 100;
    font-size: 12px;
    line-height: 20px;
    overflow: auto;

    .h3 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 1em;
    }

    p {
        margin: 1em 0;
    }

    .btn {
        margin: 20px auto;
    }

    @media(min-width: $lg) {
        padding: 50px 0 40px;

        .btn {
            width: 120px;
            margin: 0 0 0 auto;
        }
    }
}

strong,
b {
    font-weight: 700;
}

// responsive video block

.video-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 20px;
    transition: box-shadow 0.2s ease;

    &:hover {
        box-shadow: 0px 4px 40px #66FDD0;
    }
}

.video-container iframe,
.video-container object,
.video-container embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// responsive video block

.tag-status {
    display: inline-flex;
    align-items: center;
    height: 24px;
    padding: 2px 8px 0;
    font-size: 12px;
    line-height: 1em;
    border-radius: 30px;

    &.mvp {
        color: #D84747;
        background-color: #FFB5B5;
    }

    &.beta {
        color: #036A8B;
        background-color: #AAFAFF;
    }

    &.alfa {
        color: #0A9A32;
        background-color: #C8FFEC;
    }

    &.release {
        color: #987704;
        background-color: #FFFDCD;
    }

    &.closed {
        color: #FFFFFF;
        background-color: #333333;
    }
}

.bg-gradient {
    position: absolute;
    width: 1000px;
    height: 1000px;
    background: radial-gradient(50% 50% at 50% 50%, rgba(74, 254, 191, 0.25) 0%, rgba(217, 217, 217, 0) 100%);
    border-radius: 50%;
    z-index: -1;

    left: 0;
    top: 0;
    transform: translate(-50%, -50%);
}

.header-fixed {
    header {
        position: fixed;
        backdrop-filter: blur(8px);
        background-color: var(--color-bg-blur, rgba(14, 14, 16, .25));
    }
    main {
        margin-top: 80px;   
    }
}

.promo {
    position: fixed;
    top: 22%;
    right: 2%;
    width: 100%;
    max-width: 232px;
    margin: 10px !important;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-self: center;
    text-align: center;
    z-index: 110;

    a {
        display: flex;
        justify-content: center;
        align-items: center;
        align-content: center;
        text-decoration: none;
    }

    img {
        max-width: 108px;
        border: 0;
    }

    .btn {
        margin: 15px 4px;
    }
}

.CookieConsent {
    align-items: center !important;
}

.CookieConsent div {
    flex: 1 1 !important;
    margin: 30px 20px !important;
}

.CookieConsent div:nth-child(1) {
    flex: 2 1 !important;
    text-align: right;
}

#rcc-confirm-button {
    color: $black !important;
    background: $grad !important;
    border-radius: 10px !important;
    padding: 0.75em 1.25em !important;
}

@media(max-width: $sm) {
    .promo {
        position: relative;
        flex-direction: row;
        max-width: 340px;

        .btn {
            margin: 15px 4px 10px;
            font-size: 16px;
        }
    }
}
