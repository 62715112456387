
// Icons ---------------------

i.svg-5ire {
    background: url($icons) 9.136842105263156% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-abey {
    background: url($icons) 18.535338345864663% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-abstract {
    background: url($icons) 18.535338345864663% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-ailayer {
    background: url($icons) 0 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-algorand {
    background: url($icons) 9.022556390977444% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-ancient8 {
    background: url($icons) 18.045112781954888% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-aptos {
    background: url($icons) 9.409249668794411% 0 no-repeat !important;
    width: 24.304px;
    height: 24px;
}
i.svg-arbitrum-nova {
    background: url($icons) 27.55789473684211% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-arbitrum-one {
    background: url($icons) 97.41697416974169% 98.14126394052045% no-repeat !important;
    width: 19px;
    height: 19px;
}
i.svg-areon {
    background: url($icons) 27.55789473684211% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-artela {
    background: url($icons) 27.55789473684211% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-astar {
    background: url($icons) 0 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-astar-zkevm {
    background: url($icons) 9.022556390977444% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-aurora {
    background: url($icons) 18.045112781954888% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-avalanche {
    background: url($icons) 27.06766917293233% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-b2 {
    background: url($icons) 36.58045112781955% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-base {
    background: url($icons) 36.58045112781955% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-berachain {
    background: url($icons) 36.58045112781955% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-bitcoin {
    background: url($icons) 36.58045112781955% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-bitgert {
    background: url($icons) 0 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-bitlayer {
    background: url($icons) 9.022556390977444% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-blast {
    background: url($icons) 18.045112781954888% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-bob {
    background: url($icons) 27.06766917293233% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-boba {
    background: url($icons) 36.090225563909776% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-bsc {
    background: url($icons) 45.60300751879699% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-bsv {
    background: url($icons) 45.60300751879699% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-caduceus {
    background: url($icons) 45.60300751879699% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-canto {
    background: url($icons) 45.60300751879699% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-cardano {
    background: url($icons) 45.60300751879699% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-celo {
    background: url($icons) 0 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-coinflux {
    background: url($icons) 9.022556390977444% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-core-dao {
    background: url($icons) 18.045112781954888% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-cronos {
    background: url($icons) 27.06766917293233% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-cyber {
    background: url($icons) 36.090225563909776% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-dbk-chain {
    background: url($icons) 45.11278195488722% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-default {
    background: url($icons) 0 9.090909090909092% no-repeat !important;
    width: 24.304px;
    height: 24px;
}
i.svg-degen {
    background: url($icons) 54.625563909774435% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-degen-chain {
    background: url($icons) 54.625563909774435% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-dfk-chain {
    background: url($icons) 54.625563909774435% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-dos-chain {
    background: url($icons) 54.625563909774435% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-eluvio {
    background: url($icons) 54.625563909774435% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-energi {
    background: url($icons) 54.625563909774435% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-enuls {
    background: url($icons) 0 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-eos-evm {
    background: url($icons) 9.022556390977444% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-ethereum {
    background: url($icons) 18.045112781954888% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-ethereum-classic {
    background: url($icons) 27.06766917293233% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-evmos {
    background: url($icons) 36.090225563909776% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-fantom {
    background: url($icons) 45.11278195488722% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-flare {
    background: url($icons) 54.13533834586466% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-flow {
    background: url($icons) 63.648120300751884% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-fraxtal {
    background: url($icons) 63.648120300751884% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-fuse {
    background: url($icons) 63.648120300751884% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-gatechain {
    background: url($icons) 63.648120300751884% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-gnosis {
    background: url($icons) 63.648120300751884% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-godwoken {
    background: url($icons) 63.648120300751884% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-gravity {
    background: url($icons) 63.648120300751884% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-ham {
    background: url($icons) 0 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-harmony {
    background: url($icons) 9.022556390977444% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-heco {
    background: url($icons) 18.045112781954888% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-hedera {
    background: url($icons) 27.06766917293233% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-hychain {
    background: url($icons) 36.090225563909776% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-icp {
    background: url($icons) 45.11278195488722% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-immutable-x {
    background: url($icons) 54.13533834586466% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-immutable-zkevm {
    background: url($icons) 63.1578947368421% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-inevm {
    background: url($icons) 72.67067669172933% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-iotex {
    background: url($icons) 72.67067669172933% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-kakarot {
    background: url($icons) 72.67067669172933% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-kava {
    background: url($icons) 72.67067669172933% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-klaytn {
    background: url($icons) 72.67067669172933% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-kroma {
    background: url($icons) 72.67067669172933% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-kusama {
    background: url($icons) 72.67067669172933% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-layer-zero {
    background: url($icons) 72.67067669172933% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-layeredge {
    background: url($icons) 0 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-liberty-2-x {
    background: url($icons) 9.022556390977444% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-lightlink {
    background: url($icons) 18.045112781954888% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-linea {
    background: url($icons) 27.06766917293233% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-lisk {
    background: url($icons) 36.090225563909776% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-loopring {
    background: url($icons) 45.11278195488722% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-manta {
    background: url($icons) 54.13533834586466% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-mantle {
    background: url($icons) 63.1578947368421% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-merlin {
    background: url($icons) 72.18045112781955% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-metis {
    background: url($icons) 81.69323308270677% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-mint {
    background: url($icons) 81.69323308270677% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-mode {
    background: url($icons) 81.69323308270677% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-moonbeam {
    background: url($icons) 81.69323308270677% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-morph {
    background: url($icons) 81.69323308270677% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-multiversx {
    background: url($icons) 81.69323308270677% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-near {
    background: url($icons) 81.69323308270677% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-neon-evm {
    background: url($icons) 81.69323308270677% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-neutron {
    background: url($icons) 81.69323308270677% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-okt-chain {
    background: url($icons) 0 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-ontology {
    background: url($icons) 9.022556390977444% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-opbnb {
    background: url($icons) 18.045112781954888% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-optimism {
    background: url($icons) 27.06766917293233% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-palm {
    background: url($icons) 36.090225563909776% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-platon {
    background: url($icons) 45.11278195488722% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-polkadot {
    background: url($icons) 54.13533834586466% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-polygon {
    background: url($icons) 63.1578947368421% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-polygon-zkevm {
    background: url($icons) 72.18045112781955% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-pulsechain {
    background: url($icons) 81.203007518797% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-qchain {
    background: url($icons) 90.71578947368421% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-quadrans {
    background: url($icons) 90.71578947368421% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-rari-chain {
    background: url($icons) 90.71578947368421% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-redlight {
    background: url($icons) 90.71578947368421% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-redstone {
    background: url($icons) 90.71578947368421% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-rollux {
    background: url($icons) 90.71578947368421% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-ronin {
    background: url($icons) 90.71578947368421% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-rootstock {
    background: url($icons) 90.71578947368421% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-sanko {
    background: url($icons) 90.71578947368421% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-satoshivm {
    background: url($icons) 90.71578947368421% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-scroll {
    background: url($icons) 0 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-secret {
    background: url($icons) 9.022556390977444% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-sei {
    background: url($icons) 18.045112781954888% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-shibarium {
    background: url($icons) 27.06766917293233% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-shimmer {
    background: url($icons) 36.090225563909776% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-solana {
    background: url($icons) 45.11278195488722% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-soneium {
    background: url($icons) 54.13533834586466% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-starknet {
    background: url($icons) 63.1578947368421% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-sui {
    background: url($icons) 72.18045112781955% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-syscoin {
    background: url($icons) 81.203007518797% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-taiko {
    background: url($icons) 90.22556390977444% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-telos {
    background: url($icons) 99.73834586466164% 0 no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-tezos {
    background: url($icons) 99.73834586466164% 9.090909090909092% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-thundercore {
    background: url($icons) 99.73834586466164% 18.181818181818183% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-ton {
    background: url($icons) 99.73834586466164% 27.272727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-tron {
    background: url($icons) 0 0 no-repeat !important;
    width: 25px;
    height: 24px;
}
i.svg-ultron {
    background: url($icons) 99.73834586466164% 36.36363636363637% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-unichain {
    background: url($icons) 99.73834586466164% 45.45454545454545% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-vechain {
    background: url($icons) 99.73834586466164% 54.54545454545455% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-velas {
    background: url($icons) 99.73834586466164% 63.63636363636363% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-viction {
    background: url($icons) 99.73834586466164% 72.72727272727273% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-vizing {
    background: url($icons) 99.73834586466164% 81.81818181818181% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-wax {
    background: url($icons) 99.73834586466164% 90.9090909090909% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-wemix {
    background: url($icons) 0 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-x-layer {
    background: url($icons) 9.022556390977444% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-xai {
    background: url($icons) 18.045112781954888% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-xrp-ledger {
    background: url($icons) 27.06766917293233% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-zetachain {
    background: url($icons) 36.090225563909776% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-zilliqa {
    background: url($icons) 45.11278195488722% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-zircuit {
    background: url($icons) 54.13533834586466% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-zkfair {
    background: url($icons) 63.1578947368421% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-zklink-nova {
    background: url($icons) 72.18045112781955% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-zksync {
    background: url($icons) 81.203007518797% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}
i.svg-zora {
    background: url($icons) 90.22556390977444% 100% no-repeat !important;
    width: 24px;
    height: 24px;
}