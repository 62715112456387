// colors 
$black: #141616;
$gray: #1E2121;
$gray2: #343D3D;
$gray3: #ACBEC0;
$green1: #4AFEBF;
$green2: #51EEDA;

$red: #D84747;

$grad: linear-gradient(180deg, #4AFEBF 0%, #51EEDA 100%);

// breakpounts

$xs: 0;
$sm: 576px;
$max-sm: 575px;
$md: 768px;
$lg: 992px;
$xl: 1300px;

// background images

$dashed: '../pics/dashed.svg';
$use-link: '../pics/use-link.svg';
$arrow: '../pics/arrow.svg';
$img-search: '../pics/icons/i-search.svg';
$icons: '../pics/nets/sprite.svg';

// colorbox images
$colorbox-border: '../../pics/colorbox/border.png';
$colorbox-controls: '../../pics/colorbox/controls.png';
$colorbox-loading: '../../pics/colorbox/loading.gif';
$colorbox-loading-bg: '../../pics/colorbox/loading_background.png';
$colorbox-overlay: '../../pics/colorbox/overlay.png';

// svgsprite size
$sprite1: 290px;
$sprite2: 288px;