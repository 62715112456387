.sec-project {
    position: relative;
    z-index: 1;
    padding-top: 44px;

    padding-bottom: 60px;

    h1 {
        font-weight: normal;
        color: rgba(#fff, 0.75);
        @include text-18-24();
    }

    picture {
        display: block;
        margin: 2em 0;

        img {
            border-radius: 20px;
            overflow: hidden;
        }
    }

    .node {
        img {
            max-width: 100%;
            height: auto;
        }
        .align-center {
            display: block;
            margin: 0 auto;
        }
    }

    @media(min-width: $lg) {
        padding-bottom: 100px;

        .sec-bg {
            opacity: 1;
            top: -100px;
        }
    }
}

.project__card {
    background-color: $gray;
    padding: 1.5em 1em 2em;
    border-radius: 20px;
    margin-bottom: 40px;

    @media(min-width: $lg) {
        padding: 24px 32px 32px;
        margin-bottom: 60px;
    }
}

.project__about {
    h2 {
        @include text-h2();
    }

    h3 {
        @include text-h3();
    }

    ul,
    ol {
        padding-left: 1em;
        margin-bottom: 1.5em;

        li {
            margin: 0.5em 0;
        }
    }

    .video-container {
        margin: 1.5em 0;
    }
}

.project__alike {
    padding: 1.5em 1em 1em;
    background-color: $gray;
    border-radius: 20px;

    .title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 16px;
    }

    @media(min-width: $lg) {
        padding: 24px 24px 24px;
    }

    @media(min-width: $xl) {
        padding: 32px 24px 24px;

        .title {
            font-size: 20px;
        }

    }
}


.project__alike-item {
    display: flex;
    font-size: 16px;
    color: $gray3;
    font-weight: bold;
    text-decoration: none;
    align-items: center;
    padding: 16px 0;
    border-bottom: 1px solid rgba(#fff, 0.1);

    &:last-child {
        border-bottom: 0;
    }

    .logo {
        width: 46px;
        height: 46px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 16px;
        flex-shrink: 0;

        img {
            display: block;
            width: 100%;
        }
    }

    @media(min-width: $xl) {
        font-size: 18px;
    }
}